$body-background: #cccccc;
$background-color-white: #ffffff;
$background-color-black: #000000;
$font-family-sans-serif: "Poppins", sans-serif;
$primary-color: #ffffff;
$text-color-black: black;
$text-color-white: white;
$text-color-red: red;
$text-color-grey: rgb(192, 192, 192);
$text-color-blue: blue;
$container-color: #172c4c;
$bac-color: #172c4c;

$font-weights: (
  "regular": 500,
  "medium": 600,
  "bold": 700,
);

@mixin breakpoint($point) {
  @if $point == md {
      // 768px
      @media (min-width: 48em) {
          @content;
      }
  }
}
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
);

$Poppins: "Poppins", sans-serif;

// colors
$dark: rgb(29, 29, 29);
$light: #fff;
$primary: rgb(162, 162, 246);
$bg: rgb(244, 244, 255);

// spacing
$spacing-md: 16px;
$spacing-lg: 32px;

// border radius
$borderRadius: 12px;

// header height
$headerHeight: 70px;