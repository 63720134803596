@import "./variables/variables.scss";

#root {
  height: 100%;
}

body {
  width: 100%;
  box-sizing: border-box;
  margin: 0;
}
.Container {
  padding: 20px !important;

}
.top-app {
  padding-top: 15px;
}
.top {
  margin: 0px;
  #{&}__p {
    color: $text-color-blue;
  }
  #{&}__h3 {
    font-weight: map-get($font-weights, bold);
  }
  #{&}__des {
    color: $text-color-grey;
  }
}
.image-header {
  #{&}-img {
    display: flex;
    @media (max-width: 767px) {
     display: none;
    }
  }
}
.navbar {
  width: 100%;
  height: 80%;
  background-color: $background-color-black;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.navbar a {
  color: $text-color-white;
  font-size: 15px;
  text-decoration: none;
  margin: 10px;

}
.navbar .link {
  margin-right: 50px;
}

.main-row {
  justify-content: center;
}

.footer p {
  color: blue;
  text-align: center;
}
